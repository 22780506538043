<template>
  <i-modal
    :title="$t(edit ? 'editing' : 'add', { value: $tc('contact', 2) })"
    :value="value"
    :load="loading"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-row>
      <v-col cols="12" md="6">
        <ValidationProvider
          vid="`first_name-${index}`"
          :name="$t('first_name')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            v-model="contact.first_name"
            :error-messages="errors[0]"
            :label="$t('first_name')"
            class="secondary--text"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          vid="`last_name-${index}`"
          :name="$t('last_name')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            v-model="contact.last_name"
            :error-messages="errors[0]"
            :label="$t('last_name')"
            class="secondary--text"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          vid="email-${index}`"
          :name="$tc('email', 1)"
          rules="email"
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            v-model="contact.email"
            :error-messages="errors[0]"
            :label="$tc('email', 1)"
            class="secondary--text"
            key="email-input"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          vid="`phone_number-${index}`"
          :name="$t('phone_number')"
          rules="required|numeric"
          v-slot="{ errors }"
        >
          <v-text-field
            class="secondary--text"
            maxlength="14"
            outlined
            :error-messages="errors[0]"
            :label="$t('phone_number')"
            v-model="contact.phone"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
    </v-row>
  </i-modal>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: [Object],
      default: () => null
    },
    id: {
      type: Number
    },
    mode: {
      type: String
    }
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      contact: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        customer: this.id,
        personal_info: this.id
      },
      edit: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.edit = this.toEdit !== null
          this.contact =
            this.toEdit !== null
              ? { ...this.toEdit }
              : {
                  first_name: '',
                  last_name: '',
                  email: '',
                  phone: '',
                  customer: this.id,
                  personal_info: this.id
                }
        }
      }
    }
  },
  methods: {
    async submit() {
      if (!this.loading) {
        try {
          this.loading = true
          if (this.mode === 'customer') {
            !this.edit
              ? await this.$api.customer.contact.create({ form: this.contact })
              : await this.$api.customer.contact.edit({
                  pk: this.contact.id,
                  form: this.contact
                })
          } else {
            if (this.mode === 'supplier' && this.contact.customer) {
              delete this.contact.customer
              let info = { supplier: this.id }

              Object.assign(this.contact, info)
              console.log(this.contact)
            }
            !this.edit
              ? await this.$api.supplier.contact.create({ form: this.contact })
              : await this.$api.supplier.contact.edit({
                  pk: this.contact.id,
                  form: this.contact
                })
          }
          this.$emit('done')
          this.$emit('input', false)
          this.$toast.success(
            `${this.$tc('contact', 1)} ${this.$tc(
              this.edit ? 'edited' : 'created',
              1
            )}`
          )
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
