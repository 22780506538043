<route>
{
  "meta": {
    "permission": [
      "supplier.change_supplier"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form @submit.prevent="handleSubmit(submit)">
          <i-toolbar
            :title="`${$t('edit')} ${$tc('supplier', 1)}`"
            :loading="loading"
          ></i-toolbar>
          <v-card-text>
            <!-- Modales -->
            <dialterms v-model="dialog2" :to-edit="toEdit2" @done="getTerms" />

            <!-- Campos -->
            <v-row class="pt-4">
              <!-- Nombre de la compañia -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="company_name"
                  rules="required"
                  :name="$t('company_name')"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    outlined
                    :error-messages="errors[0]"
                    :label="$t('company_name')"
                    v-model="supplier.company_name"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Nombre -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="first_name"
                  rules="required"
                  :name="$t('first_name')"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    outlined
                    :error-messages="errors[0]"
                    :label="$t('first_name')"
                    class="secondary--text"
                    v-model="supplier.first_name"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Apellido -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="last_name"
                  rules="required"
                  :name="$t('last_name')"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    v-model="supplier.last_name"
                    :error-messages="errors[0]"
                    :label="$t('last_name')"
                    class="secondary--text"
                    key="last_name-input"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Correo -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="email"
                  rules="email"
                  :name="$tc('email', 1)"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    outlined
                    :error-messages="errors[0]"
                    :label="$tc('email', 1)"
                    v-model="supplier.email"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Teléfono -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="phone_number"
                  :name="$t('phone_number')"
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    counter
                    maxlength="14"
                    outlined
                    persistent-hint
                    :error-messages="errors[0]"
                    :label="$t('phone_number')"
                    v-model="supplier.phone"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Activo -->
              <v-col cols="12" md="4">
                <v-switch
                  class="v-switch mt-2"
                  color="primary"
                  name="active"
                  :label="$t('active')"
                  v-model="supplier.active"
                ></v-switch>
              </v-col>
            </v-row>

            <!-- Impuestos -->
            <v-row
              v-if="
                getPermissions([`base_config.change_supplierglobaltax`]) ||
                isAdmin
              "
              class="mt-3"
            >
              <!-- Impuesto -->
              <v-col cols="12" md="4">
                <v-switch
                  name="tax"
                  color="primary"
                  :label="$tc('tax', 1)"
                  v-model="supplier.tax"
                ></v-switch>
              </v-col>

              <!-- Número de pago de impuestos -->
              <v-col cols="12" md="8" lg="4">
                <ValidationProvider
                  v-show="!supplier.tax"
                  vid="tax_id"
                  :name="$tc('tax_id', 1)"
                  :rules="supplier.tax ? '' : 'required|min:4|max:50'"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    outlined
                    :error-messages="errors[0]"
                    :label="$tc('tax_id', 2)"
                    v-model="supplier.tax_number"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>

            <!-- Cŕedito -->
            <v-row class="mt-0">
              <!-- Crédito -->
              <v-col
                v-if="getPermissions([`base_config.view_term`]) || isAdmin"
                cols="12"
                md="4"
              >
                <v-switch
                  color="primary"
                  name="has_credit"
                  :label="$tc('has_credit', 1)"
                  v-model="supplier.has_credit"
                ></v-switch>
              </v-col>

              <!-- Límite de crédito -->
              <v-col v-show="supplier.has_credit" cols="12" md="4">
                <ValidationProvider
                  vid="credit_limit"
                  :name="$t('credit_limit')"
                  :rules="{ required: supplier.has_credit }"
                  v-slot="{ errors }"
                >
                  <i-money
                    :disabled="!getPermissions([`base_config.change_term`])"
                    v-if="supplier.has_credit"
                    autocomplete="off"
                    outlined
                    v-model="supplier.credit[0].credit_limit"
                    :error-messages="errors[0]"
                    :label="$t('credit_limit')"
                    class="secondary--text"
                    key="credit_limit-input"
                  ></i-money>
                </ValidationProvider>
              </v-col>

              <!-- Días para pagar -->
              <v-col v-show="supplier.has_credit" cols="12" md="4">
                <ValidationProvider
                  vid="term"
                  :name="$t('day_terms')"
                  :rules="{ required: supplier.has_credit }"
                  v-slot="{ errors }"
                >
                  <v-select
                    :disabled="!getPermissions([`base_config.change_term`])"
                    v-if="supplier.has_credit"
                    autocomplete="off"
                    outlined
                    v-model="supplier.credit[0].term"
                    :items="days"
                    item-text="days"
                    item-value="pk"
                    :error-messages="errors[0]"
                    :label="$t('day_terms')"
                    class="secondary--text"
                    append-icon="fa-plus"
                    @click:append="openTerms()"
                  ></v-select>
                </ValidationProvider>
              </v-col>
            </v-row>

            <!-- tabs -->
            <v-tabs v-model="tab" centered icons-and-text class="mt-0">
              <v-tabs-slider></v-tabs-slider>
              <v-tab href="#tab-1">
                {{ $tc('address', 1) }}
                <v-icon> fa-map-marked-alt </v-icon>
              </v-tab>
              <v-tab
                v-if="
                  getPermissions([`supplier.view_suppliercontacts`]) || isAdmin
                "
                href="#tab-2"
              >
                {{ $tc('contact', 2) }}
                <v-icon> fa-address-book </v-icon>
              </v-tab>
            </v-tabs>

            <!-- Dirección -->
            <div v-if="tab === 'tab-1'" class="mt-0 pb-5">
              <v-row class="infocard mx-0 mb-3">
                <v-col cols="12">
                  <h4 class="primary--text text--accent-3">
                    {{ $t('company_address') }}
                  </h4>
                </v-col>

                <!-- Dirección -->
                <v-col cols="12" md="4">
                  <ValidationProvider
                    vid="address"
                    :name="$t('address')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      autocomplete="off"
                      outlined
                      v-model="supplier.address_info[0].address1"
                      :error-messages="errors[0]"
                      :label="$t('address')"
                      class="secondary--text"
                      key="address-input"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <!-- Dirección 2 -->
                <v-col cols="12" md="4">
                  <ValidationProvider
                    vid="address2"
                    :name="$t('address2')"
                    rules=""
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      autocomplete="off"
                      outlined
                      v-model="supplier.address_info[0].address2"
                      :error-messages="errors[0]"
                      :label="$t('address2')"
                      class="secondary--text"
                      key="address2-input"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <!-- Ciudad -->
                <v-col cols="12" md="4">
                  <ValidationProvider
                    vid="city"
                    :name="$t('city')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      autocomplete="off"
                      outlined
                      v-model="supplier.address_info[0].city"
                      :error-messages="errors[0]"
                      :label="$t('city')"
                      class="secondary--text"
                      key="city-input"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <!-- Estado -->
                <v-col cols="12" md="4">
                  <ValidationProvider
                    vid="state"
                    :name="$t('state')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      autocomplete="off"
                      outlined
                      v-model="supplier.address_info[0].state"
                      :error-messages="errors[0]"
                      :label="$t('state')"
                      class="secondary--text"
                      key="state-input"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <!-- Código postal -->
                <v-col cols="12" md="4">
                  <ValidationProvider
                    vid="zip_code"
                    :name="$t('zip_code')"
                    rules="required|numeric"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      autocomplete="off"
                      outlined
                      v-model="supplier.address_info[0].zip_code"
                      :error-messages="errors[0]"
                      :label="$t('zip_code')"
                      class="secondary--text"
                      key="zip_code-input"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <!-- País -->
                <v-col cols="12" md="4">
                  <ValidationProvider
                    vid="country"
                    :name="$t('country')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      autocomplete="off"
                      outlined
                      v-model="supplier.address_info[0].country"
                      :error-messages="errors[0]"
                      :label="$t('country')"
                      class="secondary--text"
                      :items="countries"
                      item-text="name"
                      item-value="pk"
                      append-icon="fa-search"
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row class="infocard mx-0">
                <v-col cols="12">
                  <h4 class="primary--text text--accent-3">
                    {{ $t('dispatchAddress') }}
                  </h4>
                </v-col>

                <!-- Dirección -->
                <v-col cols="12" md="4">
                  <ValidationProvider
                    vid="address3"
                    :name="$t('address')"
                    :rules="{ required: !supplier.samePayment }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      autocomplete="off"
                      outlined
                      v-model="supplier.address_info[1].address1"
                      :error-messages="errors[0]"
                      :label="$t('address')"
                      :disabled="supplier.samePayment"
                      class="secondary--text"
                      key="address3-input"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <!-- Dirección 2 -->
                <v-col cols="12" md="4">
                  <ValidationProvider
                    vid="address4"
                    :name="$t('address')"
                    rules=""
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      autocomplete="off"
                      outlined
                      v-model="supplier.address_info[1].address2"
                      :disabled="supplier.samePayment"
                      :error-messages="errors[0]"
                      :label="$t('address2')"
                      class="secondary--text"
                      key="address4-input"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <!-- Ciudad -->
                <v-col cols="12" md="4">
                  <ValidationProvider
                    vid="city2"
                    :name="$t('city')"
                    :rules="{ required: !supplier.samePayment }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      autocomplete="off"
                      outlined
                      v-model="supplier.address_info[1].city"
                      :error-messages="errors[0]"
                      :label="$t('city')"
                      :disabled="supplier.samePayment"
                      class="secondary--text"
                      key="city-input"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <!-- Estado -->
                <v-col cols="12" md="4">
                  <ValidationProvider
                    vid="state2"
                    :name="$t('state')"
                    :rules="{ required: !supplier.samePayment }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      autocomplete="off"
                      outlined
                      v-model="supplier.address_info[1].state"
                      :error-messages="errors[0]"
                      :label="$t('state')"
                      :disabled="supplier.samePayment"
                      class="secondary--text"
                      key="state-input"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <!-- Código postal -->
                <v-col cols="12" md="4">
                  <ValidationProvider
                    vid="zip_code2"
                    :name="$t('zip_code')"
                    :rules="{
                      required: !supplier.samePayment,
                      numeric: 'numeric'
                    }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      autocomplete="off"
                      outlined
                      v-model="supplier.address_info[1].zip_code"
                      :disabled="supplier.samePayment"
                      :error-messages="errors[0]"
                      :label="$t('zip_code')"
                      class="secondary--text"
                      key="zip_code-input"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <!-- País -->
                <v-col cols="12" md="4">
                  <ValidationProvider
                    vid="country2"
                    :name="$t('country')"
                    :rules="{
                      required: !supplier.samePayment
                    }"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      autocomplete="off"
                      outlined
                      v-model="supplier.address_info[1].country"
                      :disabled="supplier.samePayment"
                      :error-messages="errors[0]"
                      :label="$t('country')"
                      class="secondary--text"
                      :items="countries"
                      item-text="name"
                      item-value="pk"
                      append-icon="fa-search"
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </div>

            <!-- Contactos -->
            <v-row v-if="tab === 'tab-2'" class="mt-0 pb-5">
              <v-col cols="12">
                <contacts :value="supplier.pk" :mode="'supplier'" />
              </v-col>
            </v-row>
          </v-card-text>
        </form>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import dialterms from '../../components/terms/modal.vue'
import contacts from '../../components/contact/list.vue'

export default {
  components: {
    dialterms,
    contacts
  },
  data() {
    return {
      dialog: false,
      dialog2: false,
      toEdit: null,
      toEdit2: null,
      tab: 'tab-1',
      loading: false,
      levels: [],
      countries: [],
      days: [],
      supplier: {
        company_name: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        tax: false,
        tax_number: '',
        hasUser: false,
        has_credit: false,
        level_id: null,
        active: true,
        company: null,
        user: null,
        address_info: [
          {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip_code: null,
            country: null,
            address_type: 'PAY'
          },
          {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip_code: null,
            country: null,
            address_type: 'DISP'
          }
        ],
        credit: [{ credit_limit: 0, term: 1 }],
        samePayment: false
      }
    }
  },
  mounted() {
    this.getSupplier()
    this.getCountries()
    this.getTerms()
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    })
  },
  methods: {
    ...mapActions({
      showConfirmation: 'confirmation/confirmationValue'
    }),
    openTerms(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog2 = true
    },
    async getCountries() {
      const country = await this.$api.country.list({})
      this.countries = country.data
    },
    async getTerms() {
      const terms = await this.$api.terms.list({})
      this.days = terms.data.results
    },
    getSupplier() {
      this.dialogEdit = true
      const validation = this.codification({
        mode: 'decode',
        code: this.$route.params.pk
      })
      this.$api.supplier.show({ pk: validation }).then((res) => {
        this.supplier = res.data
        delete this.supplier.company
        if (this.supplier.credit.length == 0) {
          this.supplier.credit = [{ credit_limit: 0, term: 1 }]
        }
        this.$route.meta.title = this.$t('edit', { model: '' })
      })
    },
    async submit() {
      try {
        this.loading = true
        if (!this.supplier.has_credit) {
          delete this.supplier.credit
        }
        this.supplier.hasUser = false
        if (!this.supplier.hasUser) {
          this.supplier.user = null
        }
        this.$api.supplier.edit({
          pk: this.supplier.pk,
          form: this.supplier
        })
        this.$emit('input', false)
        this.$toast.success(
          `${this.$tc('supplier', 1)} ${this.$tc('edited', 2)}`
        )
        this.$router.push({ name: 'supplier' })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
